// 分项查询模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 分项查询-查企业
export const searchCompany = (data) => {
    return request({
        url: '/api/company/searchCompany',
        method: "post",
        data
    })
}

// 分项查询-查业绩
export const searchResult = (data) => {
    return request({
        url: '/api/result/searchResult',
        method: "post",
        data
    })
}

// 分项查询-查资质
export const searchCert = (data) => {
    return request({
        url: '/api/cert/searchCert',
        method: "post",
        data
    })
}

// 分项查询-查项目经理
export const searchManager = (data) => {
    return request({
        url: '/api/result/searchManager',
        method: "post",
        data
    })
}

// 分项查询-查技术负责人
export const searchTechnician = (data) => {
    return request({
        url: '/api/result/searchTechnician',
        method: "post",
        data
    })
}

// 分项查询-查人员
export const searchPersonByDto = (data) => {
    return request({
        url: '/api/result/searchPersonByDto',
        method: "post",
        data
    })
}

// 分项查询-查招标
export const searchNoticeListByDto = (data) => {
    return request({
        url: '/api/notice/searchNoticeListByDto',
        method: "post",
        data
    })
}

// 分项查询-查荣誉
export const searchHonor = (data) => {
    return request({
        url: '/api/honor/searchHonor',
        method: "post",
        data
    })
}

// 分项查询-查诚信
export const searchPunish = (data) => {
    return request({
        url: '/api/honor/searchPunish',
        method: "post",
        data
    })
}

// 分项查询-查评价
export const searchEvaluate = (data) => {
    return request({
        url: '/api/honor/searchEvaluate',
        method: "post",
        data
    })
}
